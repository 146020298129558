<template>
  <a-form
    ref="form"
    :model="form"
    :rules="rules"
    class="columns is-mini-gap is-multiline"
  >
    <a-form-item class="column is-12" :label="$t('Phonetic Name')" name="phoneticName">
      <a-input v-model:value="form.phoneticName" :size="size" type="text" />
    </a-form-item>
    <a-form-item class="column is-12" :label="$t('Partner Name')" name="name">
      <a-input v-model:value="form.name" :size="size" type="text" />
    </a-form-item>
    <a-form-item class="column is-12" :label="$t('Postal Code')" name="postalCode">
      <a-input v-model:value.trim="form.postalCode" :size="size" type="text" />
    </a-form-item>
    <a-form-item class="column is-6" :label="$t('Country')" name="country">
      <InputCountry v-model:value="form.country" />
    </a-form-item>
    <a-form-item class="column is-6" :label="$t('Prefecture / State')" name="state">
      <InputState
        v-model:value="form.state"
        :country-code="form.country"
        value-locale="en"
      />
    </a-form-item>
    <a-form-item class="column is-12" :label="$t('Address')" name="address">
      <a-input v-model:value="form.address" :size="size" type="text" />
    </a-form-item>
    <!--
      <a-form-item
      class="column is-12"
      label="Partner ID"
      name="id"
      >
      <a-input
      v-model:value="form.id"
      :size="size"
      type="text"
      />
      </a-form-item>
    -->
    <a-form-item class="column is-12" :label="$t('Relationship')" name="relationship">
      <a-select v-model:value="form.relationship" :size="size">
        <a-select-option value="PO">
          {{ $t('Property Owner') }}
        </a-select-option>
        <a-select-option value="Vendor">
          {{ $t('Vendor') }}
        </a-select-option>
        <a-select-option value="CSC">
          {{ $t('CS Company') }}
        </a-select-option>
        <a-select-option value="UIP">
          {{ $t('Upsell Item Provider') }}
        </a-select-option>
      </a-select>
    </a-form-item>
    <a-form-item
      v-if="form.relationship === 'CSC'"
      class="column is-12"
      :label="$t('Anshin Account')"
      name="anshinAccount"
    >
      <a-select
        v-model:value="form.anshinAccountIds"
        :size="size"
        mode="multiple"
        @change="handleAnshinAccountChange"
      >
        <a-select-option v-for="item in anshinAccounts" :key="item.id" :value="item.id">
          {{ item.domain }}
        </a-select-option>
      </a-select>
    </a-form-item>
    <a-form-item class="column is-12" :label="$t('Property Name')" name="propertyIds">
      <a-select
        v-model:value="form.propertyIds"
        :size="size"
        option-filter-prop="key"
        mode="multiple"
      >
        <a-select-option
          v-for="property in propertyList"
          :key="property.name"
          :value="property.newPropertyCode"
        >
          {{ property.name }}
        </a-select-option>
      </a-select>
    </a-form-item>
    <a-form-item class="column is-6" :label="$t('Business')" name="business">
      <a-input v-model:value="form.business" :size="size" type="text" />
    </a-form-item>
    <a-form-item class="column is-6" :label="$t('Individual / Corporate')" name="businessType">
      <a-select v-model:value="form.businessType" :size="size">
        <a-select-option value="Individual">
          {{ $t('individual') }}
        </a-select-option>
        <a-select-option value="Corporate">
          {{ $t('corporate') }}
        </a-select-option>
      </a-select>
    </a-form-item>
    <a-form-item class="column is-12" :label="$t('Memo')" name="memo">
      <a-textarea v-model:value="form.memo" :auto-size="{ minRows: 6 }" />
    </a-form-item>

    <div class="column is-12 m-t-4x" :class="{ 'has-text-right': value }">
      <a-button
        v-if="value"
        :disabled="loading"
        :size="size"
        class="button-secondary-outline m-r-3x"
        style="min-width: 115px"
        @click="resetFields"
      >
        {{ $t('Cancel') }}
      </a-button>
      <a-button
        :loading="isSubmitting"
        :size="size"
        class="m-r-3x"
        style="min-width: 155px"
        type="primary"
        @click="handleSubmit"
      >
        {{ $t('Save') }}
      </a-button>
    </div>
  </a-form>
</template>

<i18n>
{
"en": {
"Partner Name": "Partner Name",
"Postal Code": "Postal Code",
"Country": "Country",
"Prefecture / State": "Prefecture / State",
"Address": "Address",
"Phonetic Name": "Phonetic Name",
"Relationship": "Relationship",
"Property Owner": "Property Owner",
"Vendor": "Vendor",
"CS Company": "CS Company",
"Upsell Item Provider": "Upsell Item Provider",
"Property Name": "Property Name",
"Business": "Business",
"Individual / Corporate": "Individual / Corporate",
"individual": "individual",
"corporate": "corporate",
"Memo": "Memo",
"Cancel": "Cancel",
"Save": "Save"
},
"ja": {
"Partner Name": "取引先名称",
"Postal Code": "郵便番号",
"Country": "国名",
"Prefecture / State": "都道府県",
"Address": "住所",
"Phonetic Name": "取引先よみ",
"Relationship": "関係性（業者なのか、お客様なのか）",
"Property Owner": "客様",
"Vendor": "業者",
"CS Company": "CS Company",
"Upsell Item Provider": "Upsell Item Provider",
"Property Name": "物件正式名称",
"Business": "事業",
"Individual / Corporate": "個人事業主・法人",
"individual": "個人事業主",
"corporate": "法人",
"Memo": "Memo",
"Cancel": "取り消し",
"Save": "保存"
}
}
</i18n>

<script>
import InputCountry from '@/components/InputCountry';
import InputState from '@/components/InputState';

export default {
  name: 'PartnerForm',
  components: {
    InputCountry,
    InputState,
  },
  props: {
    isSubmitting: {
      type: Boolean,
    },
    propertyList: {
      type: Array,
      default: () => [],
    },
    value: {
      type: Object,
      default() {
        return null;
      },
    },
  },
  emits: ['submit', 'close', 'cancel'],
  data() {
    return {
      dateFormat: 'YYYY-MM-DD',
      anshinAccounts: [],
      form: {
        name: '',
        postalCode: '',
        country: '',
        state: '',
        address: '',
        phoneticName: '',
        relationship: '',
        propertyName: '',
        business: '',
        businessType: '',
        memo: '',
        propertyIds: [],
        anshinAccountIds: [],
      },
      rules: {
        name: [
          {
            required: true,
            message: this.$t('Partner Name is required'),
            trigger: 'change',
          },
        ],
        postalCode: [
          {
            required: true,
            message: this.$t('Postal Code is required'),
            trigger: 'change',
          },
        ],
        country: [
          {
            required: true,
            message: this.$t('Country is required'),
            trigger: ['change', 'blur'],
          },
        ],
        state: [
          {
            required: true,
            message: this.$t('State is required'),
            trigger: ['change', 'blur'],
          },
        ],
        address: [
          {
            required: true,
            message: this.$t('Address is required'),
            trigger: 'change',
          },
        ],
        relationship: [
          {
            required: true,
            message: this.$t('Relationship is required'),
            trigger: 'change',
          },
        ],
        businessType: [
          {
            required: true,
            message: this.$t('Business Type is required'),
            trigger: 'change',
          },
        ],
      },
      size: 'large',
    };
  },
  watch: {
    value: {
      immediate: true,
      handler(nv) {
        if (nv) {
          const keys = Object.keys(nv);
          keys.forEach((k) => {
            this.form[k] = nv[k];
            if (k === 'properties') {
              this.form.propertyIds = nv[k].length ? nv[k].map((p) => p.newPropertyCode) : [];
            }
          });
        }
      },
    },
    'form.state': {
      handler(nv) {
        if (nv) {
          this.$refs.form.validateFields('state');
        }
      },
    },
    'form.country': {
      handler(nv) {
        if (nv) {
          this.$refs.form.validateFields('country');
        }
      },
    },
  },
  created() {
    this.$store.dispatch('properties/listAnshinAccounts').then((res) => {
      this.anshinAccounts = res.list;
    });
  },
  methods: {
    async handleSubmit() {
      try {
        await this.$refs.form.validate();
        const { propertyIds, ...rest } = this.form;
        if (this.value) {
          const payload = {
            ...rest,
            id: this.value.id,
            properties: propertyIds,
          };
          await this.$store.dispatch('partners/update', payload);
          this.$emit('close');

          return;
        }

        this.$emit('submit', { ...rest, properties: propertyIds });
      } catch (e) {
        console.log(e);
      }
    },
    resetFields() {
      this.$refs.form.resetFields();
      if (this.value) {
        this.$emit('cancel');
      }
    },
  },
};
</script>
